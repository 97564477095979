import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [{ key: 'help', text: 'Help', url: '/help' }]

const HelpPage = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>Help Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default HelpPage
